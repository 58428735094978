import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Homepage.js";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Contact from "./pages/Contact.js";
import AboutUs from "./pages/AboutUs.js";
import WhatWeDo from "./pages/WhatWeDo";
import References from "./pages/References.js";
import Blog from "./pages/Blog.js";
import MediaPlanning from "./pages/MediaPlanning";
import MedyaSatınAlma from "./pages/MedyaSatinAlma.js";
import Pr from "./pages/Pr.js";
import Reklam from "./pages/Reklam.js";
import Strategy from "./pages/Strategy.js"

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/whatwedo" element={<WhatWeDo />} />
        <Route path="/references" element={<References />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/media-planning" element={<MediaPlanning />} />
        <Route path="/mediasatinalma" element={<MedyaSatınAlma />} />
        <Route path="/pr" element={<Pr />}></Route>
        <Route path="/reklam" element={<Reklam />}></Route>
        <Route path="/strategy" element={<Strategy />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
