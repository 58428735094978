import React from 'react';
import '../styles/ServiceBox.css'; 
import Web from "../images/web.png"
import Pr from "../images/pr.png"
import Halk from "../images/halk.png"

const ServiceBox = () => {
  return (
    <section className="services-section">
      <div className="service-card">
        <div className="icon">
          <img src={Web} alt="Web Tasarım Icon" />
        </div>
        <h3>Web Tasarım & Yazılım Çözümleri</h3>
        <p>
          Dijital varlığınızı güçlendirmek ve etkileyici bir çevrimiçi deneyim sunmak için özelleştirilmiş web tasarım ve güçlü yazılım çözümlerimizle işinizi dijital dünyada öne çıkarın. En son teknoloji ve yaratıcı tasarım anlayışımızla sizin için özel çözümler üretiyoruz.
        </p>
      </div>

      <div className="service-card">
        <div className="icon">
          <img src={Pr} alt="Dijital PR Icon" />
        </div>
        <h3>Dijital PR: Markanızı Çevrimiçi Dünyada Tanıtın</h3>
        <p>
          Markanızın dijital dünyada güçlü bir varlık kazanması için özel olarak tasarlanmış dijital PR stratejilerimizle tanışın. Dijital PR ile markanızın öyküsünü, etkileşimli ve etkileyici bir şekilde paylaşın.
        </p>
      </div>

      <div className="service-card">
        <div className="icon">
          <img src={Halk} alt="Halkla İlişkiler Icon" />
        </div>
        <h3>Halkla İlişkiler: Markanızı Öne Çıkarın, İletişiminizi Güçlendirin</h3>
        <p>
          Markanızın itibarını güçlendirmek ve etkili iletişim stratejileriyle öne çıkmak için halkla ilişkiler uzmanlığımızı keşfedin. Kapsamlı PR stratejilerimizle markanızın mesajını doğru kitlelere ileterek güçlü bir varlık oluşturmanıza yardımcı oluyoruz.
        </p>
      </div>
    </section>
  );
};

export default ServiceBox;