import React from 'react';
import '../styles/Strategic.css';
import illustration from '../images/illustration.png'; 

const StrategicSection = () => {
  return (
    <section className="strategic-section">
      <div className="content">
        <div className="strategic-left">
          <h1>
            Stratejik Düşünce, <br />
            Yaratıcı Tasarım, <br />
            Güçlü Reklam: İşte Biz!
          </h1>
          <ul>
            <li>
              <span className="check-icon">✔</span>
              Geleceği şekillendirmek bugünün adımlarını dikkatlice planlamayı ve hedeflerimize odaklanmayı gerektirir.
            </li>
            <li>
              <span className="check-icon">✔</span>
              Yaratıcı tasarım, sıradanı olağanüstü kılmanın anahtarıdır.
            </li>
            <li>
              <span className="check-icon">✔</span>
              Güçlü reklam, tüketicilerin kalplerine ve zihinlerine yerleşir, unutulmaz bir etki bırakır.
            </li>
          </ul>
          <button className="cta-button">Neler Yapıyoruz? →</button>
        </div>
        <div className="strategic-right">
          <img src={illustration} alt="Illustration" />
        </div>
      </div>
    </section>
  );
}

export default StrategicSection;