import React from 'react';
import "../styles/Hero.css";
import heroImg from "../images/hero.png"

const HeroSection = () => {
  return (
    <section className="hero-section">
     <div className="hero-content">
        <h1>20 Yıldır Başarıyla</h1>
        <div className="highlighted-text">
          <span>Yazdığımız Hikayelere</span>
        </div>
        <h1>Hoş Geldiniz!</h1>
      </div>
      <div className="hero-sub-content">
          <h2>Size Özel Bir Hikaye Yaratmaya Hazırız.</h2>
          <div className="hero-buttons">
            <button className="dark-button">Çalışmalarımız</button>
            <button className="light-button">İletişim</button>
          </div>
        </div>
      <div className="hero-illustration">

        <img src={heroImg} alt="Hero Illustration" />
      </div>
    </section>
  );
}

export default HeroSection;