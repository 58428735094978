import React, { useState } from 'react';
import '../styles/Header.css';
import Logo from '../images/logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Dropdown menüyü hover ile açma ve kapatma işlemi
  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={Logo} alt="Markaevi Logo" />
      </div>
      <nav className="navigation">
        <ul>
          <li><Link to="/">Anasayfa</Link></li>
          <li><Link to="/about">Biz Kimiz?</Link></li>

          {/* Neler Yapıyoruz dropdown */}
          <li
            className="dropdown"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Link to="/whatwedo" className="dropdown-button">
              Neler Yapıyoruz
            </Link>
            {dropdownOpen && (
              <ul className="dropdown-content">
                <li><Link to="/medyasatinalma">Medya Satın Alma</Link></li>
                <li><Link to="/media-planning">Medya Planlama</Link></li>

                <li><Link to="/pr">PR</Link></li>
                <li><Link to="/rekalm">Reklam</Link></li>
                <li><Link to="/strategy">Strateji</Link></li>
                <li><Link to="/web-design">Web Yazılım & Tasarım</Link></li>
              </ul>
            )}
          </li>

          <li><Link to="/references">Referanslarımız</Link></li>
          <li><Link to="/blog">Blog</Link></li>
        </ul>
      </nav>
      <div className="contact-button">
        <Link to="/contact">
          <button>Bize Ulaşın</button>
        </Link>
      </div>
    </header>
  );
};

export default Header;