// src/pages/MedyaSatınAlma.js
import React from 'react';
import "../styles/MediaPlanning.css";
import MediaPlanningImage from "../images/satin.png"; // Görsel dosyanızın yolu

const MedyaSatınAlma = () => {
  return (
    <section className="media-planning-section">
      <div className="media-planning-container">
        
        {/* Sol Sütun - Başlık ve Metin */}
        <div className="media-planning-content">
          <h1>Medya Satın Alma</h1>
          <p>
            Medya satın alma, hedef kitlenize en etkili şekilde ulaşmak için doğru mecraları belirlemek ve bu mecralarda reklam alanlarını stratejik olarak satın almaktır. MarkaEvi olarak, bütçenizi en verimli şekilde kullanarak reklam kampanyalarınızı maksimum etkiyle yönlendirecek medya planlama ve satın alma hizmeti sunuyoruz.
          </p>
          <p>
            Hedef kitlenizin medya tüketim alışkanlıklarını analiz ederek, televizyon, dijital medya, sosyal medya, radyo ve basılı medya gibi farklı kanallarda markanızın en görünür olacağı noktalara ulaşmanızı sağlıyoruz.
          </p>
        </div>

        {/* Sağ Sütun - Görsel */}
        <div className="media-planning-image">
          <img src={MediaPlanningImage} alt="Medya Satın Alma" />
        </div>
      </div>
    </section>
  );
};

export default MedyaSatınAlma;