import React from 'react';
import "../styles/WhatWeDo.css";
import Medya from "../images/medya.jpg"; // Add relevant images
import Pr from "../images/pr.jpg";
import Reklam from "../images/reklam.jpg";
import Strateji from "../images/strateji.jpg";
import Web from "../images/webtas.jpg";
import ETic from "../images/etic.jpg";

const WhatWeDo = () => {
  const services = [
    { image: Medya, title: "Medya Planlama ve Satın Alma", link: "/medya-planlama" },
    { image: Pr, title: "PR", link: "/pr" },
    { image: Reklam, title: "Reklam", link: "/reklam" },
    { image: Strateji, title: "Strateji", link: "/strateji" },
    { image: Web, title: "Web Tasarım ve Yazılım", link: "/web-tasarim" },
    { image: ETic, title: "E-Ticaret", link: "/e-ticaret" }
  ];

  return (
    <section className="what-we-do-section">
      {/* Page Title Section */}
      <div className="page-title">
        <h1>Neler Yapıyoruz?</h1>
      </div>

      {/* Services Grid Section */}
      <div className="services-grid">
        {services.map((service, index) => (
          <div className="service-box" key={index}>
            <img src={service.image} alt={service.title} className="service-image" />
            <h2>{service.title}</h2>
            <button className="learn-more-button">
              <a href={service.link}>Detaylı Bilgi</a>
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhatWeDo;