import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa'; // Social media icons
import "../styles/Footer.css";
import Logo from "../images/logo.png";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-columns">

        {/* Column 1: Company Info */}
        <div className="footer-column">
          <img src={Logo} alt="MarkaEvi Logo" className="footer-logo" />
          <p>
            <strong>Adres:</strong> 19 Mayıs Cad. Turhan İş Merkezi No. 25 D.9 34360 Şişli/İstanbul
          </p>
          <p>
            <strong>Telefon:</strong>
            <a href="tel:+905326673893">+90 532 667 38 93</a>
          </p>
          <p>
            <strong>Email:</strong>
            <a href="mailto:info@markaevi.com.tr">info@markaevi.com.tr</a>
          </p>
        </div>

        {/* Column 2: Biz Kimiz */}
        <div className="footer-column">
          <h4>Biz Kimiz?</h4>
          <ul>
            <li><Link to="/">Anasayfa</Link></li>
            <li><Link to="/about">Biz Kimiz?</Link></li>
            <li><Link to="/services">Neler Yapıyoruz?</Link></li>
            <li><Link to="/references">Referanslarımız</Link></li>
            <li><Link to="/blog">Blog</Link></li>
            <li><Link to="/contact">İletişim</Link></li>
          </ul>
        </div>

        {/* Column 3: Neler Yapıyoruz */}
        <div className="footer-column">
          <h4>Neler Yapıyoruz?</h4>
          <ul>
            <li>Medya Planlama ve Satın Alma</li>
            <li>PR</li>
            <li>Reklam</li>
            <li>Strateji</li>
            <li>Web Tasarım & Yazılım</li>
            <li>E-Ticaret</li>
          </ul>
        </div>

        {/* Column 4: Social Media */}
        <div className="footer-column">
          <h4>Bizi Takip Edin</h4>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          </div>
        </div>

      </div>

      <div className="footer-bottom">
        <p>© 2024 MarkaEvi • All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;