// src/pages/MediaPlanning.js
import React from 'react';
import '../styles/MediaPlanning.css';
import MediaImage from '../images/medyaplan.jpg'; // Replace with the actual image

const Reklam = () => {
  return (
    <section className="media-planning-section">
      <div className="media-planning-container">
        {/* Left Column: Text */}
        <div className="media-planning-text">
          <h1>Reklam 

          </h1>
          <p>
            Medya planlama, bir kampanyanın reklam bütçesinin doğru medya araçlarına dağıtılması ve en yüksek etkileşimi sağlayacak şekilde kullanılması sürecidir. Markanızın mesajını doğru zamanda, doğru kitleye ulaştırmak için titizlikle çalışıyoruz.
          </p>
          <p>
            Televizyon, dijital medya, basılı yayınlar ve dış mekan reklamları gibi farklı medya araçlarını kullanarak stratejik bir medya planı oluşturuyoruz. Hedef kitlenize en uygun araçlarla markanızı daha geniş kitlelere ulaştırıyoruz.
          </p>
        </div>

        {/* Right Column: Image */}
        <div className="media-planning-image">
          <img src={MediaImage} alt="Media Planning" />
        </div>
      </div>
    </section>
  );
};

export default Reklam;