import React from 'react'

const PreFooter = () => {
  return (
    <div> 
    <div className="grow-section">
      <div className="grow-column">
        <h2>Markanızı birlikte büyütelim.</h2>
      </div>
     
      <div className="grow-column">
        <button className="dark-button">Çalışmalarımız</button>
        <button className="light-button">İletişim</button>
      </div>
    </div></div>
  )
}

export default PreFooter