import React from 'react';
import "../styles/AboutUs.css"
import Right from "../images/paperplaneR.png"
import Left from "../images/paperplaneL.png"
import Biz from "../images/biz.png"
import Digital from "../components/Digital.js"


const AboutUs = () => {
  return (
    <section className="about-us-section">
      <div className="about-us-container">
      <div className="highlighted-text">
          <span>Biz Kimiz?</span>
        </div>        <div className="icon-left">
          <img src={Left} alt="Paper plane left" />
        </div>
        <div className="icon-right">
          <img src={Right} alt="Paper plane right" />
        </div>
      </div>
      <section className="about-us-section-two">
      <div className="about-us-content">
        <div className="left-column">
          <h1 className="about-heading">
            Markanızın Geleceğini <br /> Şekillendiriyoruz.
          </h1>
        </div>
        <div className="right-column">
          <p className="about-description">
            2004 yılından kurulan MarkaEvi Halkla İlişkiler ve Reklam Ajansı;
            Marka Yönetimi, Stratejik İletişim Danışmanlığı, İçerik Yönetimi,
            Etkinlik Yönetimi, Reklam Konsept Geliştirme, Medya Planlama ve Satın
            Alma hizmetleri sunan entegre iletişim ajansıdır.
          </p>
        </div>
      </div>
    </section>
    <div className="about-us-container">
      {/* Görsel kısmı */}
      <div className="about-us-image">
        <img src={Biz} alt="About Us" />
      </div>

      {/* Metin kısmı */}
      <div className="about-us-content">
        <h1>Hakkımızda</h1>
        <p>
          İletişim stratejilerini oluştururken özel dizayn mantığı ile hareket ederek
          müşterileriyle birlikte “Marka Katma Değeri” yaratmayı ilke edinen MarkaEvi
          Halkla İlişkiler ve Reklam Ajansı, iletişim alanında kaliteli, özel ve geniş
          paydada hizmet veren bir çözüm ortağı sorumluluğunu taşımaktadır.
        </p>
        <p>
          MarkaEvi, dijitalleşen dünyada web yazılım, tasarım, SEO, sosyal medya gibi
          ihtiyaçlarınız için de artık 20 yıllık deneyimiyle sizlerle.
        </p>
      </div>
    </div>
<Digital/>

    </section>
  );
};

export default AboutUs;