// src/pages/BlogArchive.js
import React from 'react';
import "../styles/Blog.css";

const blogs = [
  {
    title: "Blog Post 1",
    image: "path-to-image1.jpg", // replace with actual image paths
    tag: "Tag 1",
  },
  {
    title: "Blog Post 2",
    image: "path-to-image2.jpg",
    tag: "Tag 2",
  },
  {
    title: "Blog Post 3",
    image: "path-to-image3.jpg",
    tag: "Tag 3",
  },
  {
    title: "Blog Post 4",
    image: "path-to-image4.jpg",
    tag: "Tag 4",
  },
  {
    title: "Blog Post 5",
    image: "path-to-image5.jpg",
    tag: "Tag 5",
  },
  {
    title: "Blog Post 6",
    image: "path-to-image6.jpg",
    tag: "Tag 6",
  },
];

const BlogArchive = () => {
  return (
    <section className="blog-archive">
      <h1>Blog</h1>
      <div className="blog-grid">
        {blogs.map((blog, index) => (
          <div key={index} className="blog-card">
            <img src={blog.image} alt={blog.title} />
            <h3>{blog.title}</h3>
            <p className="blog-tag">{blog.tag}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BlogArchive;