import React from 'react';
import "../styles/References.css";
import Fakir from "../images/fakir.png";
import Avva from "../images/avva.png";
import Hgg from "../images/hgg.jpeg";
import Isgyo from "../images/isgyo.png";
import Pronto from "../images/pronto.png";
import Trend from "../images/trend.jpeg";
import Tds from "../images/tds.webp";
import Seramiksan from "../images/seramik.png";
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const References = () => {
  const references = [
    { id: 1, logo: Fakir, name: 'Fakir' },
    { id: 2, logo: Avva, name: 'Avva' },
    { id: 3, logo: Hgg, name: 'HGG' },
    { id: 4, logo: Isgyo, name: 'İş GYO' },
    { id: 5, logo: Pronto, name: 'Pronto Tour' },
    { id: 6, logo: Trend, name: 'Trend GYO' },
    { id: 7, logo: Tds, name: 'Türkiye Denizciler Sendikası' },
    { id: 8, logo: Seramiksan, name: 'Seramiksan' },
  ];

  return (
    <section className="references-section">
      <div className="page-title">
        <h1>Referanslarımız</h1>
      </div>
      
      <div className="references-grid">
        {references.map((ref) => (
          <div className="reference-box" key={ref.id}>
            <img src={ref.logo} alt={`${ref.name} logo`} />
            <div className="social-icons">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebookF />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default References;