import React from 'react';
import One from "../images/biz1.png"
import Two from "../images/biz2.png"
import Three from "../images/biz3.png"


const Digital = () => {
  return (
<div className="digital-transformation">
<h2>Dijital Dönüşümünüzü Birlikte Başlatalım</h2>

<div className="steps-container">
  {/* Adım 1 */}
  <div className="step">
    <img src={One} alt="Planlama" />
    <div className="step-number">01</div>
    <div className="step-description">Planlama</div>
  </div>

  {/* Arrow 1 */}
  <div className="arrow">→</div>

  {/* Adım 2 */}
  <div className="step">
    <img src={Two} alt="Tasarım" />
    <div className="step-number">02</div>
    <div className="step-description">Tasarım</div>
  </div>

  {/* Arrow 2 */}
  <div className="arrow">→</div>

  {/* Adım 3 */}
  <div className="step">
    <img src={Three} alt="Uygulama" />
    <div className="step-number">03</div>
    <div className="step-description">Uygulama</div>
  </div>
</div>
</div>  )
}

export default Digital


